import cx from "classnames";

export const Section = ({
  children,
  ...props
}: { children?: React.ReactNode } & React.HTMLAttributes<HTMLElement>) => (
  <section {...props} className={cx(props.className, "min-h-[400px]")}>
    {children}
  </section>
);

export const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h1 className="text-4xl text-center font-bold">{children}</h1>
);

export const SectionSubtitle = ({
  children,
}: {
  children: React.ReactNode;
}) => <p className="text-center">{children}</p>;
