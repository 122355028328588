import {
  CharitySection,
  HeroSection,
  RegionsSection,
  UtilitySection,
} from "./sections";

export const Homepage = () => (
  <>
    <div className="col-span-8 mx-auto">
      <HeroSection id="homepage" />
    </div>
    <div className="col-span-8 md:col-span-6 md:col-start-2 my-24">
      <UtilitySection id="utility" />
    </div>
    <div className="col-span-8 my-24">
      <CharitySection id="charity" />
    </div>
    <div className="col-span-8">
      <RegionsSection id="regions" />
    </div>
  </>
);
