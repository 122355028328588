import { Section, SectionSubtitle, SectionTitle } from "./Section";

const roadmapItems = [
  {
    title: "The HOA was formed",
    description:
      "A community was formed around digital homes that represent iconic styles of residences around the country. This HOA gathered like-minded individuals together who are dedicated to making real estate more accessible and helping out their community",
  },
  {
    title: "Construction",
    description:
      "The homes were built. Partnering with a talented designer VENDELCG we sketched, designed, and constructed these homes, creating each element to feel unique and complementary to the design",
  },
  {
    title: "Parcl TestNet — Whitelist Open",
    description:
      "Parcl TestNet goes live. Through collaboration with Parcl, users who connect their wallets to the Parcl Protocol will become eligible for the HOA mint allowlist. After completing a successful testnet trade, users will be granted access to an allowlist spot for our NFT (up to 3,889 spots), pre-sale opens first with mint cost of 0.4 SOL.",
  },
  {
    title: "Public Mint Date",
    description: "The remaining NFTs will go to a public mint at 0.65 SOL.",
  },
  {
    title: "Home Giveaway",
    description:
      "To give back to the HOA community, proceeds of the mint will be set aside to purchase a member of the community a home. This grand prize winner will be chosen from a group of five finalists. This is the first of many initiatives the HOA will take to support community members and the HOA's mission to bridge the gap in the real estate market.",
  },
  {
    title: "Launch Party",
    description:
      "NFT holders will receive a personalized invite to exclusive launch parties and future IRL events.",
  },
  {
    title: "Parcl Protocol Public Launch",
    description:
      "When Parcl Protocol is open to the public, eligible HOA NFT holders can receive a variety of benefits for holding an HOA NFT. Pending eligibility, this could include protocol perks such as early Protocol access, access to future drops, Protocol participation & referral rewards, or exclusive access to new features and merchandise.",
  },
  {
    title: "Future Locations",
    description:
      "NFT holders will have early access to allowlist spots for future drops, as new locations are added to the Parcl Protocol.",
  },
];

const RoadmapItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <li className="step grid grid-cols-roadmap gap-2 min-h-[4rem] place-items-center justify-items-start">
    <div className="flex flex-col">
      <h3 className="text-xl font-bold">{title}</h3>
      <p>{description}</p>
    </div>
  </li>
);

export const RoadmapSection = () => (
  <div className="col-span-8 py-32">
    <Section className="mt-1.5" id="roadmap">
      <SectionTitle>Roadmap</SectionTitle>
      <SectionSubtitle>
        Building the best HOA ever to exist.
      </SectionSubtitle>
      <div className="grid md:grid-cols-4">
        <ol className="col-span-2 md:col-start-2 grid grid-flow-row auto-rows-[1fr]">
          {roadmapItems.map(({ title, description }, index) => (
            <RoadmapItem
              key={`${title}-${index}`}
              title={title}
              description={description}
            />
          ))}
        </ol>
      </div>
    </Section>
  </div>
);
