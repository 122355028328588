import cx from "classnames";
import { ReactComponent as DiscordSvg } from "./assets/discord-icon.svg";
import { ReactComponent as TwitterSvg } from "./assets/twitter-icon.svg";

export const ExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="inline text-white"
  >
    {children}
  </a>
);

interface SocialIconsProps {
  primary: boolean;
}
export const SocialIcons = (props: SocialIconsProps) => {
  const { primary } = props;

  const socialIconClasses = cx(
    "w-8 m-auto align-middle inline-block h-[32px]",
    primary ? "text-parcl-light-blue" : "text-white"
  );

  return (
    <>
      <ExternalLink href="https://twitter.com/hoa_nft">
        <TwitterSvg className={socialIconClasses} />
      </ExternalLink>
      <ExternalLink href="https://discord.gg/parcl">
        <DiscordSvg className={socialIconClasses} />
      </ExternalLink>
    </>
  );
};
