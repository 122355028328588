import { SectionSubtitle, SectionTitle } from "./Section";
import { ExternalLink } from "./utils";

export const FinalCTASection = ({ id }: { id: string }) => (
  <section className="mt-1.5 grid grid-cols-4" id={id}>
    <div className="col-span-4 flex flex-col items-center justify-center bg-parcl-light-blue/10 rounded-lg py-16">
      <SectionTitle>The friendliest HOA to ever exist.</SectionTitle>
      <div className="mt-4">
        <SectionSubtitle>What are you waiting for?</SectionSubtitle>
      </div>
      <ExternalLink href="https://www.magiceden.io/launchpad/parcl">
        <button className="rounded-lg font-bold text-parcl-light-blue bg-parcl-light-blue/20 hover:bg-parcl-light-blue/10 w-64 py-4 mt-8">
          Join the neighborhood
        </button>
      </ExternalLink>
    </div>
  </section>
);
