import { Link } from "react-router-dom";
import LAJpg from "./assets/LA.jpg";
import NYJpg from "./assets/NY.jpg";
import AZJpg from "./assets/AZ.jpg";
import FLJpg from "./assets/FL.jpg";
import hoaHeroSvg from "./assets/hoa-border.svg";
import hoaPng from "./assets/hoa-nft.png";
import hoaEarlyAccessPng from "./assets/hoa-early-access.png";
import hoaExclusiveAccessPng from "./assets/hoa-exclusive-access.png";
import hoaProtocolPng from "./assets/hoa-protocol.png";
import hoaMerchPng from "./assets/hoa-merch.png";
import { Section, SectionTitle } from "./Section";
import { ExternalLink } from "./utils";

export const HeroSection = ({ id }: { id: string }) => {
  return (
    <Section
      id={id}
      className="container grid grid-cols-12 md:gap-x-4 py-32 bg-hero-pattern bg-no-repeat bg-right bg-[length:950px_850px] bg-origin-padding"
      style={{
        boxShadow: "0 0 16px 16px #0d1938 inset",
      }}
    >
      <div className="flex flex-col justify-start col-span-12 lg:col-span-5 top-50">
        <div className="flex justify-center place-content-center md:justify-start">
          <img
            src={hoaHeroSvg}
            alt="Homeowners association"
            className="w-[64%]"
          />
        </div>
        <span className="mt-8 font-sans text-3xl font-bold text-parcl-light-blue">
          The friendliest, most rewarding HOA to ever exist.
        </span>
        <p className="mt-4 font-sans">
          Connecting like-minded individuals who are interested in
          Real Estate, NFTs, and crypto.
        </p>
        <p className="mt-4 font-sans">
          Homeowners Association is an NFT project in collaboration with Parcl, consisting of 7,777
          unique combinations of iconic homes from four unique cities. Ownership
          grants eligible holders exclusive benefits to real-world events, early
          access to the Parcl Protocol, Protocol benefits, access to
          future drops, discounts on merchandise, and participation in
          charitable initiatives.
        </p>
        <br />
        <div className="flex justify-center md:justify-start">
          <ExternalLink href="https://www.magiceden.io/launchpad/parcl">
            <button className="w-64 py-4 font-bold rounded-lg text-parcl-light-blue bg-parcl-light-blue/20 hover:bg-parcl-light-blue/10">
              Join the neighborhood
            </button>
          </ExternalLink>
        </div>
        {/* <Countdown date={launchDate} renderer={LaunchCountdown} /> */}
      </div>
      <div className="col-span-1" />
      <div className="col-span-12 lg:col-span-6 min-h-[300px] justify-items-center align-bottom flex flex-col">
        <img
          src={hoaPng}
          style={{ width: "500px", paddingRight: "18px", margin: "0 auto" }}
          alt="animation of houses"
        />
      </div>
    </Section>
  );
};

export const CharitySection = ({ id }: { id: string }) => {
  return (
    <Section id={id}>
      <SectionTitle>Our Mission</SectionTitle>
      <div className="mx-auto md:w-1/2">
        <p className="mb-4 text-center">
          Parcl's mission is to bring real estate to everyone
        </p>
        <p className="text-center">
          This means leveling the playing field; lowering the barriers to entry
          for individuals who are trying to get started in real estate investing
          & offering opportunities to folks that have been locked out of or
          disadvantaged by the current state of the traditional real estate
          market
        </p>
      </div>
      <div className="flex flex-wrap mt-16">
        <div className="basis-full md:flex-1">
          <span className="font-sans text-3xl font-bold text-parcl-light-blue">
            Dedicated to giving back
          </span>
          <p className="mt-4 font-sans">
            The Parcl community is on a mission to lower the barrier to entry for
            individuals trying to get started in real estate investing, and to
            offer opportunities to folks that have been disadvantaged by the
            current state of the traditional real estate market.
          </p>
          <p className="mt-4 font-sans">
            To show the HOA's commitment to Parcl's mission, proceeds from the HOA
            mint will be pooled into a charitable fund. These funds will be
            distributed to deserving individuals/families to help them with
            their housing costs.
          </p>
          <div>
            <hr className="inline-block w-1/6 align-middle" />{" "}
            <Link to="/roadmap" className="text-parcl-light-blue">
              View Roadmap
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center mt-16 basis-full md:mt-0 md:flex-1">
          <img
            src={LAJpg}
            alt="House in Los Angeles"
            className="rounded-2xl max-h-[300px]"
          />
        </div>
      </div>
    </Section>
  );
};

const regions = {
  LA: {
    image: LAJpg,
    placeholder: "House in Los Angeles",
    name: "Los Angeles",
  },
  NYC: {
    image: NYJpg,
    placeholder: "House in New York",
    name: "New York",
  },
  AZ: {
    image: AZJpg,
    placeholder: "House in Phoenix",
    name: "Phoenix",
  },
  FL: {
    image: FLJpg,
    placeholder: "House in Miami",
    name: "Miami",
  },
};
export const RegionsSection = ({ id }: { id: string }) => (
  <Section className="mt-1.5" id={id}>
    <div className="gap-8 xs:columns-1 sm:columns-2 md:columns-4">
      {Object.keys(regions).map((key) => {
        // @ts-ignore
        const region = regions[key];
        return (
          <div className="mb-8">
            <img
              src={region.image}
              alt={region.placeholder}
              className="mb-4 rounded-2xl"
            />
            <p className="text-xl text-center">{region.name}</p>
          </div>
        );
      })}
    </div>
  </Section>
);

type UtilitySquareProps = {
  title: string;
  description: string;
  image: string;
};
const UtilitySquare = ({ title, description, image }: UtilitySquareProps) => {
  return (
    <div className="flex gap-4">
      <img
        src={image}
        alt="placeholder"
        className="w-[50px] h-[50px] justify-self-end"
      />
      <div className="flex flex-col">
        <span className="text-xl font-bold">{title}</span>
        <p className="min-h-[150px] break-words">{description}</p>
      </div>
    </div>
  );
};

export const UtilitySection = ({ id }: { id: string }) => (
  <Section className="mt-1.5" id={id}>
    <SectionTitle>Utility for the protocol and the real world</SectionTitle>
    <div className="gap-8 mt-8 columns-1 md:columns-2">
      <UtilitySquare
        title="Early access to mainnet"
        image={hoaEarlyAccessPng}
        description="When the Parcl Protocol launches, eligible NFT holders will be among the first to have access to the protocol, provide liquidity and earn rewards"
      />
      <UtilitySquare
        title="Protocol rewards"
        image={hoaProtocolPng}
        description="Become eligible for rewards by participating early in the protocol and completing activities such as minting, liquidity provision (farming), and trading; you may also be eligible for future drops"
      />
      <UtilitySquare
        title="IRL Events"
        image={hoaExclusiveAccessPng}
        description="Access to real world events for Parcl users and HOA community members, with HOA NFTs as a VIP access pass - this will include the global launch party"
      />
      <UtilitySquare
        title="Discounted Merch"
        image={hoaMerchPng}
        description="NFT holders will be eligible for discounts on custom Parcl & HOA gear"
      />
    </div>
  </Section>
);
