import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./App.css";
import parclLogoPng from "./assets/parcl-logo.png";

import { FinalCTASection } from "./FinalCTA";
import { ExternalLink, SocialIcons } from "./utils";

function App() {
  return (
    <>
      <div className="w-screen px-4 text-white bg-parcl-dark-blue">
        <header className="container flex justify-between pt-8 mx-auto">
          <ExternalLink href="https://parcl.co">
            <img src={parclLogoPng} className="h-[40px]" alt="parcl logo" />
          </ExternalLink>
          <div className="flex items-center">
            <Link to="/" className="mx-2 md:mx-4">
              Home
            </Link>
            <Link to="/roadmap" className="mx-2 md:mx-4">
              Roadmap
            </Link>
            <ExternalLink href="https://shop.hoa.house/">
              Merch
            </ExternalLink>
            <div className="mx-2 space-x-4 md:mx-8">
              <SocialIcons primary />
            </div>
          </div>
        </header>
        <main className="container grid min-h-screen grid-cols-8 px-4 pb-32 mx-auto bg-gray md:px-16">
          <Outlet />
          <div className="col-span-8 md:col-span-6 md:col-start-2">
            <FinalCTASection id="final-cta" />
          </div>
        </main>
      </div>

      <footer className="font-sans text-white bg-parcl-dark-blue">
        <div className="px-4 bg-parcl-light-blue/20">
          <div className="container grid grid-cols-8 py-8 mx-auto">
            <div className="flex flex-col col-span-6 col-start-2 md:col-span-2 md:col-start-7 md:justify-end md:items-end">
              <span className="text-xl text-left uppercase text-bold md:text-right">
                Join our community
              </span>

              <div className="flex space-x-4">
                <SocialIcons primary={false} />
              </div>
            </div>
            <div className="flex justify-center col-span-7 col-start-2 mt-4 md:col-span-8 md:justify-start">
              <p>
                © 2022 Parcl, Inc. All rights reserved. — 
                <ExternalLink href="http://parcl.co/privacy">
                  Privacy Policy
                </ExternalLink>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
